export class Megrendeles {

  public feladat: string;
  public nev: string;
  public email: string;
  public telefonszam: string;


  constructor(feladat: string, nev: string, email: string, telefonszam: string) {
    this.feladat = feladat;
    this.nev = nev;
    this.email = email;
    this.telefonszam = telefonszam;
  }
}
