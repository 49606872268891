<div class="fs-container" style="position: relative">

  <div *ngIf="!showContent" class="preloader">
    <style>
      div#fs-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #40555a;
      }
      div#fs-loader svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      path.st1 {
        stroke: #fff;
        stroke-dasharray: 250;
        opacity: 0;
        animation: animate 5s;
      }

      @keyframes animate {
        0% {
          opacity: 0;
          fill: none;
          stroke-dashoffset: 250;
        }
        5% {
          opacity: 10;
          fill: none;
          stroke-dashoffset: 250;
        }
        45% {
          opacity: 10;
          fill: none;
          stroke-dashoffset: 0;
        }
        55% {
          opacity: 10;
          fill: none;
          stroke-dashoffset: 0;
        }
        95% {
          opacity: 10;
          fill: none;
          stroke-dashoffset: 250;
        }
        100% {
          opacity: 0;
          fill: none;
          stroke-dashoffset: 250;
        }
      }
    </style>
    <div id="fs-loader" class="prefadeout">
      <div class="fs-logo">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 595.28 208.14" style="enable-background:new 0 0 595.28 208.14;" xml:space="preserve">
    <style type="text/css">
      .st0{fill:#40555a;}
      .st1{fill:none;}
      .st2{fill:#67C2CA;}
      .st3{fill:#DEDC14;}
      .st4{fill:#FFFFFF;}
    </style>
          <g id="bcg">
      <rect class="st0" width="595.28" height="208.14"/>
    </g>
          <g id="text">
      <g>
        <path class="st1" d="M95.97,109.97V71.76h21.33v5.27h-15.11v9.53h15.11v5.46h-15.11v17.96H95.97z"/>
        <path class="st1" d="M123.82,109.97V69.51h5.66v40.46H123.82z"/>
        <path class="st1" d="M135.75,97.62c0-1.81,0.34-3.52,1.03-5.13c0.69-1.61,1.67-3.04,2.96-4.29c1.27-1.24,2.73-2.18,4.39-2.85
        c1.65-0.66,3.38-0.99,5.19-0.99c1.86,0,3.62,0.33,5.26,0.99c1.64,0.66,3.12,1.64,4.42,2.92c1.31,1.27,2.3,2.71,2.98,4.31
        c0.68,1.6,1.02,3.28,1.02,5.04c0,1.83-0.34,3.54-1,5.14c-0.67,1.6-1.65,3.02-2.94,4.25c-1.36,1.3-2.86,2.29-4.52,2.96
        c-1.65,0.67-3.39,1.01-5.22,1.01c-1.79,0-3.52-0.34-5.17-1.01c-1.65-0.67-3.12-1.64-4.41-2.91c-1.3-1.29-2.3-2.73-2.98-4.33
        C136.09,101.14,135.75,99.43,135.75,97.62z M157.02,97.68c0-2.3-0.74-4.24-2.22-5.83c-1.48-1.59-3.29-2.39-5.43-2.39
        c-2.12,0-3.93,0.8-5.42,2.39c-1.49,1.59-2.23,3.54-2.23,5.83s0.74,4.24,2.23,5.83c1.49,1.59,3.29,2.39,5.42,2.39
        s3.93-0.8,5.42-2.39S157.02,99.97,157.02,97.68z"/>
        <path class="st1" d="M193.68,111.46l-5.72-12.87c-0.44-0.99-0.79-1.87-1.07-2.65c-0.28-0.77-0.53-1.54-0.76-2.28
        c-0.38,1.13-0.72,2.1-1.02,2.91c-0.3,0.81-0.53,1.41-0.71,1.81l-5.87,13.08l-12.82-26.23h6.03l5.56,11.93
        c0.23,0.5,0.45,1.2,0.67,2.07c0.22,0.88,0.4,1.85,0.56,2.91c0.02-0.63,0.12-1.28,0.31-1.96c0.19-0.68,0.51-1.51,0.97-2.51
        l6.06-13.36h0.47l6.16,13.36c0.38,0.84,0.67,1.61,0.85,2.32c0.18,0.71,0.29,1.43,0.33,2.14c0.16-0.94,0.36-1.83,0.6-2.66
        c0.24-0.84,0.52-1.59,0.84-2.27l5.46-11.98h5.98L193.68,111.46z"/>
        <path class="st1" d="M269.03,103.31l4.96-2.3c0.47,1.71,1.33,3.01,2.58,3.9c1.25,0.9,2.85,1.34,4.8,1.34
        c1.84,0,3.31-0.51,4.4-1.54c1.09-1.03,1.63-2.4,1.63-4.12c0-2.24-1.86-4.25-5.59-6c-0.52-0.26-0.92-0.45-1.2-0.57
        c-4.21-2.05-7.02-3.91-8.42-5.57c-1.4-1.66-2.1-3.69-2.1-6.09c0-3.11,1.06-5.64,3.17-7.57c2.11-1.93,4.89-2.9,8.31-2.9
        c2.82,0,5.18,0.54,7.07,1.63c1.9,1.09,3.21,2.65,3.94,4.69l-4.86,2.51c-0.77-1.2-1.61-2.08-2.52-2.64s-1.96-0.84-3.15-0.84
        c-1.67,0-3,0.44-3.98,1.31c-0.98,0.87-1.47,2.04-1.47,3.5c0,2.3,2.16,4.44,6.47,6.42c0.33,0.16,0.59,0.28,0.78,0.37
        c3.78,1.74,6.37,3.44,7.78,5.1s2.11,3.74,2.11,6.23c0,3.62-1.14,6.5-3.42,8.63c-2.28,2.13-5.36,3.2-9.24,3.2
        c-3.25,0-5.92-0.75-8-2.25C271.04,108.24,269.68,106.1,269.03,103.31z"/>
        <path class="st1" d="M301.42,110.96V91.17h-4.33v-4.96h4.33v-9.37h5.66v9.37h4.33v4.96h-4.33v19.79H301.42z"/>
        <path class="st1" d="M321.97,90.16c0.61-1.64,1.41-2.85,2.41-3.63c1-0.78,2.24-1.17,3.72-1.17c0.8,0,1.59,0.1,2.36,0.29
        c0.77,0.19,1.54,0.47,2.28,0.84l-2.04,4.8c-0.49-0.35-1.01-0.6-1.58-0.77c-0.57-0.16-1.2-0.25-1.89-0.25
        c-1.88,0-3.23,0.75-4.05,2.26c-0.82,1.51-1.23,4.03-1.23,7.58v10.86h-5.66v-24.8h5.66V90.16z"/>
        <path class="st1" d="M359.82,99.42h-19.79c0.14,2.31,0.83,4.12,2.06,5.42c1.23,1.3,2.88,1.94,4.93,1.94
        c1.83,0,3.35-0.38,4.57-1.15c1.22-0.77,2.28-2,3.18-3.71l4.78,2.66c-1.39,2.49-3.11,4.34-5.17,5.56
        c-2.05,1.22-4.46,1.83-7.23,1.83c-3.97,0-7.13-1.21-9.47-3.63c-2.35-2.42-3.52-5.65-3.52-9.68c0-3.88,1.21-7.07,3.64-9.57
        s5.55-3.75,9.36-3.75c3.98,0,7.09,1.14,9.32,3.42c2.23,2.28,3.34,5.47,3.34,9.58V99.42z M353.21,94.62
        c-0.23-1.78-0.91-3.13-2.04-4.06c-1.13-0.93-2.68-1.4-4.65-1.4c-1.86,0-3.36,0.45-4.49,1.36c-1.13,0.91-1.92,2.27-2.38,4.1H353.21
        z"/>
        <path class="st1" d="M389,110.96h-5.66v-3.21c-1.01,1.43-2.19,2.49-3.54,3.18c-1.35,0.7-2.89,1.04-4.63,1.04
        c-3.5,0-6.25-1.2-8.26-3.59c-2.01-2.39-3.02-5.67-3.02-9.83c0-3.95,1.03-7.14,3.08-9.57c2.05-2.43,4.73-3.64,8.04-3.64
        c2.02,0,3.75,0.42,5.18,1.27c1.44,0.84,2.48,2.06,3.15,3.64v-4.05H389V110.96z M383.49,98.51c0-2.56-0.6-4.55-1.79-5.98
        c-1.19-1.43-2.86-2.14-5-2.14c-2.14,0-3.82,0.74-5.05,2.21c-1.23,1.47-1.84,3.51-1.84,6.12c0,2.63,0.59,4.66,1.77,6.08
        c1.18,1.43,2.85,2.14,5.01,2.14c2.04,0,3.69-0.77,4.97-2.32C382.85,103.07,383.49,101.03,383.49,98.51z"/>
        <path class="st1" d="M403.49,110.96h-5.66V86.21h5.32v4.05c1.13-1.72,2.41-2.98,3.85-3.77c1.44-0.79,3.13-1.19,5.08-1.19
        c1.93,0,3.53,0.4,4.8,1.21c1.27,0.81,2.24,2.04,2.9,3.69c1.3-1.71,2.69-2.95,4.16-3.73c1.47-0.78,3.15-1.17,5.03-1.17
        c1.63,0,3.06,0.3,4.28,0.9c1.22,0.6,2.17,1.47,2.85,2.62c0.31,0.54,0.54,1.18,0.69,1.93c0.15,0.75,0.22,1.82,0.22,3.21v1.98v15.01
        h-5.66V98.25c0-3.32-0.35-5.51-1.06-6.56s-1.96-1.58-3.77-1.58c-2.23,0-3.82,0.71-4.76,2.13c-0.95,1.42-1.42,3.9-1.42,7.45v11.28
        h-5.72V98.25c0-3.06-0.37-5.19-1.11-6.37c-0.74-1.18-2.01-1.77-3.82-1.77c-1.11,0-2.13,0.25-3.05,0.76
        c-0.92,0.5-1.65,1.2-2.19,2.09c-0.35,0.57-0.59,1.28-0.73,2.11c-0.14,0.84-0.21,2.38-0.21,4.62V110.96z"/>
        <path class="st1" d="M444.11,75.51c0-0.96,0.35-1.78,1.06-2.48c0.7-0.7,1.54-1.04,2.52-1.04c1.01,0,1.85,0.34,2.52,1
        c0.67,0.67,1.01,1.51,1.01,2.52c0,1.01-0.34,1.86-1.02,2.54c-0.68,0.69-1.51,1.03-2.51,1.03c-0.97,0-1.81-0.35-2.52-1.06
        C444.46,77.33,444.11,76.49,444.11,75.51z M444.86,110.96V86.21h5.66v24.74H444.86z"/>
        <path class="st1" d="M458.62,110.96V86.21h5.32v4.05c1.13-1.71,2.46-2.95,3.99-3.73c1.53-0.78,3.39-1.17,5.59-1.17
        c1.84,0,3.42,0.29,4.71,0.87c1.3,0.58,2.31,1.45,3.04,2.6c0.44,0.68,0.75,1.47,0.94,2.38c0.19,0.91,0.29,2.49,0.29,4.75v15.01
        h-5.72V98.25c0-3.25-0.4-5.42-1.19-6.49c-0.79-1.07-2.2-1.61-4.21-1.61c-1.32,0-2.5,0.24-3.54,0.73
        c-1.04,0.49-1.85,1.17-2.44,2.06c-0.42,0.59-0.71,1.35-0.87,2.28c-0.17,0.93-0.25,2.41-0.25,4.45v11.28H458.62z"/>
        <path class="st1" d="M513.33,109.42c0,4.84-0.97,8.24-2.9,10.22c-1.93,1.97-5.18,2.96-9.76,2.96c-3.41,0-6.13-0.72-8.17-2.15
        c-2.04-1.44-3.07-3.35-3.11-5.73l6.08-0.03c0.09,1.06,0.58,1.87,1.49,2.43c0.9,0.56,2.17,0.84,3.81,0.84
        c2.42,0,4.16-0.53,5.22-1.59c1.06-1.06,1.6-2.84,1.62-5.32l0.05-3.29c-1.01,1.43-2.19,2.49-3.54,3.18
        c-1.35,0.7-2.89,1.04-4.63,1.04c-3.5,0-6.25-1.2-8.26-3.59c-2.01-2.39-3.02-5.67-3.02-9.83c0-3.95,1.03-7.14,3.08-9.57
        c2.05-2.43,4.73-3.64,8.04-3.64c2.02,0,3.75,0.42,5.18,1.27c1.44,0.84,2.48,2.06,3.15,3.64v-4.05h5.66V109.42z M507.09,97.52
        c0-2.56-0.6-4.55-1.79-5.98c-1.19-1.43-2.86-2.14-5-2.14c-2.14,0-3.82,0.74-5.05,2.21c-1.23,1.47-1.84,3.51-1.84,6.12
        c0,2.63,0.59,4.66,1.77,6.08c1.18,1.43,2.85,2.14,5.01,2.14c2.04,0,3.69-0.77,4.97-2.32C506.45,102.08,507.09,100.04,507.09,97.52
        z"/>
      </g>
    </g>
          <g id="lines">
      <g>

          <rect x="245.57" y="57.07" transform="matrix(0.9999 -0.0131 0.0131 0.9999 -0.9485 3.2345)" class="st4" width="0.5" height="33.54"/>
      </g>
            <g>

          <rect x="199.67" y="81.24" transform="matrix(0.5592 -0.829 0.829 0.5592 33.4393 225.8661)" class="st4" width="58.9" height="0.5"/>
      </g>
            <g>
        <polygon class="st4" points="245.5,90.96 215.65,71.1 215.93,70.69 245.77,90.54 		"/>
      </g>
            <g>

          <rect x="210.43" y="98.41" transform="matrix(0.912 -0.4101 0.4101 0.912 -20.2947 102.7131)" class="st4" width="37.7" height="0.5"/>
      </g>
            <g>

          <rect x="254.72" y="91.41" transform="matrix(0.7885 -0.615 0.615 0.7885 -9.5386 178.633)" class="st4" width="0.5" height="23.55"/>
      </g>
            <g>

          <rect x="251.95" y="93.23" transform="matrix(0.9565 -0.2917 0.2917 0.9565 -20.7 78.2851)" class="st4" width="0.5" height="30.67"/>
      </g>
            <g>

          <rect x="233.05" y="130.55" transform="matrix(0.6923 -0.7216 0.7216 0.6923 -17.5752 220.384)" class="st4" width="33.15" height="0.5"/>
      </g>
            <g>
        <polygon class="st4" points="225.95,141.39 210.87,116.46 211.3,116.2 226.38,141.13 		"/>
      </g>
    </g>
          <g id="gombok">
      <circle class="st2" cx="231.2" cy="149.84" r="10.35"/>
            <circle class="st3" cx="248.17" cy="52.2" r="6.61"/>
            <circle class="st3" cx="208.39" cy="110.99" r="6.61"/>
            <circle class="st2" cx="212.64" cy="68.87" r="3.75"/>
            <circle class="st3" cx="264.33" cy="115.86" r="4.4"/>
            <circle class="st2" cx="246.48" cy="92.44" r="2.13"/>
    </g>
    </svg>
      </div>
    </div>
  </div>


  <div class="fs-content fs-absolute fs-hidden">

    <div class="wrapper">
      <section id="fs-preview" class="container-fluid">
        <div class="moving-object mv-triangle"></div>
        <div class="moving-object mv-object-1"></div>
        <div class="moving-object mv-object-2"></div>
        <div class="moving-object mv-object-3"></div>

        <div class="fs-navigation row">
          <div class="col-6">
            <img id="top" class="fs-logo mt-1" src="../../assets/flow_streaming.svg"/>
          </div>
          <div class="col-6 text-right">
            <button class="navbar" (click)="toggleNavbar()">
              <div [ngClass]="modalNavbar ? 'toggle-opened' : 'toggle'">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>

        <div class="fs-preview-row row">
          <div class="col-12 text-center">
            <h1>Live stream</h1>
          </div>
          <div class="col-12 text-center">
            <h2>Megoldások</h2>
          </div>
          <div class="col-12 text-center">
            <p>Élő műsorok online és helyszíni közvetítése eseményeken és konferenciákon<br>
              Egyéb élő, online kommunikációs megoldások<br>
              Technikai kivitelezés és tanácsadás</p>
          </div>
        </div>
      </section>

      <section id="fs-services" class="container-fluid">
        <div class="moving-object mv-object-4"></div>
        <div class="moving-object mv-object-5"></div>
        <div class="row">
          <div class="col-12 text-right wave">
            <h2 id="services" class="mr-5">Szolgáltatások</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 m-0 p-0 clickable" (click)="openModalServices1()">
            <div class="pink">
              <p class="title">Streaming eszközök <br>értékesítése/ stúdióépítés</p>
              <p class="description">
                Igényfelmérés, konzultáció, eszközbeszerzés, telepítés<br>
                Saját stream-es setup összeállítása<br>
                Technikai és szoftveres oktatás, support
              </p>
            </div>
          </div>
          <div class="col-md-4 m-0 p-0 clickable" (click)="openModalServices2()">
            <div class="blue">
              <p class="title">Live stream/ Broadcast <br>szolgáltatás</p>
              <p class="description">
                Élő közvetítés teljeskörű lebonyolítása<br>
                Műsor készítés<br>
                Helyszíni közvetítés és felvétel készítés
              </p>
            </div>
          </div>
          <div class="col-md-4 m-0 p-0 clickable" (click)="openModalServices3()">
            <div class="orange">
              <p class="title">Eszköz bérbeadás</p>
              <p class="description">
                Streaming eszközök<br>
                Filmes, videós eszközök, kiegészítők<br>
                Technikai tanácsadás
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="fs-contact-us" class="container-fluid">
        <div class="row">
          <div class="col-12">
            <h2 id="contacts">Kapcsolat</h2>
          </div>
        </div>
        <div class="row services">
          <div class="offset-2 col-8">
            <div class="row">
              <div class="col-md-4 text-center">
                <img src="assets/icon-map.png"/>
                <p>1027 Budapest, Kandó Kálmán utca 5.</p>
              </div>
              <div class="col-md-4 text-center">
                <img src="assets/icon-phone.png"/>
                <p>+36 70 315 5457</p>
              </div>
              <div class="col-md-4 text-center">
                <img src="assets/icon-mail.png"/>
                <p>info@flowstreaming.hu</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="fs-contact-form" class="container-fluid">
        <div class="moving-object mv-object-6"></div>
        <div class="moving-object mv-object-7"></div>
        <div class="row">
          <div class="col-12 contact-us-title">
            <div class="icon-form">
              <img src="assets/icon-form.png"/>
            </div>
            <div class="form-title">
              <p class="title mb-0">Töltsd ki</p>
              <p class="subtitle">megrendelő űrlapunkat!</p>
            </div>
          </div>
        </div>
          <form id="megrendelo-urlap" name="megrendeloUrlap">
            <div class="row" *ngIf="showSentEmail">
              <div class="col-8" style="margin-top: 0.7rem;">
                <div class="alert alert-success" role="alert">
                  Az email küldése sikeres!
                </div>
              </div>
            </div>
            <div class="row" *ngIf="showEmailError">
              <div class="col-8" style="margin-top: 0.7rem;">
                <div class="alert alert-danger" role="alert">
                  Hiba az email küldése közben!
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                  <textarea id="feladat" [(ngModel)]="model.feladat" name="feladat" placeholder="Feladat leírása" rows="6"></textarea>
                </div>
                <div class="col-md-3">
                  <input id="nev" [(ngModel)]="model.nev" name="nev" placeholder="Név"/>
                  <input id="email" [(ngModel)]="model.email" name="email" placeholder="E-mail cím"/>
                  <input id="telefonszam" [(ngModel)]="model.telefonszam" name="telefonszam" placeholder="Telefonszám"/>
                </div>
                <div class="col-md-3">
                  <button id="send" name="send" (click)="onSubmit()" class="send-btn">Elküld</button>
                </div>
            </div>
          </form>
      </section>

      <footer class="container-fluid">
        <div class="row">
          <div class="col-6 p-2">
            <p class="p-2 ml-5 m-0"><strong>FLOW</strong>STREAMING &copy; 2020 All Rights Reserved</p>
          </div>
          <div class="col-6 p-2">
            <p class="social p-2 mr-5 m-0 text-right">
              <fa-icon [icon]="faFacebook" (click)="redirectToFacebook()" class="mr-2"></fa-icon>
              <fa-icon [icon]="faInstagram" (click)="redirectToInstagram()" class="mr-2"></fa-icon>
              <fa-icon [icon]="faVimeo" (click)="redirectToVimeo()"></fa-icon>
            </p>
          </div>
        </div>
      </footer>

      <div *ngIf="openedModalServices1" [ngClass]="openedModalServices1 ? 'modalServices1 modal opened' : 'modalServices1 modal'">
        <div class="container-fluid">
          <div class="row">
            <div class="col-11 text-right">
              <h1 class="pt-5 szolgaltatasok-title">Szolgáltatások</h1>
            </div>
            <div class="col-1">
              <button class="navbar" (click)="toggleNavbar()">
                <div [ngClass]="modalNavbar ? 'toggle-opened' : 'toggle'">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="services-scrollable">
          <div class="container-fluid services-modal-pink-bg">
            <div class="row">
              <div class="col-10">
                <div class="title">Streaming eszközök</div>
                <div class="subtitle">Értékesítése</div>
              </div>
              <div class="col-2 text-right">
                <span class="close-btn" (click)="closeModalServices1()"><img src="assets/close-pink.png" width="45"/></span>
              </div>
            </div>
          </div>
          <div class="container-fluid services-modal-pink-bg">
            <div class="row">
              <div class="col-md-6">
                <div class="modal-col">
                  <p>Streameld saját magad a tartalmaidat</p>
                  <p>Személyes konzultáció, igényfelmérés</p>
                  <p>Eszközbeszerzés (Kezdő, haladó, professzionális csomagok)</p>
                  <p>Kiépítés és installálás ott és úgy, ahogy szeretnéd</p>
                  <p>Betanítás</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="modal-col">
                  <p>Folyamatos konzultációval segítjük a kezdeti lépéseidet</p>
                  <p>Igény szerint oktatás</p>
                  <p>Support</p>
                </div>
                <div class="video-container">
                  <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/427747949?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid service-modal-footer">
          <div class="row">
            <div class="col-9 p-2">
              <p class="p-2 ml-5 m-0 copyright"><strong>FLOW</strong>STREAMING &copy; 2020 All Rights Reserved</p>
            </div>
            <div class="col-3">
              <p class="social p-2 mr-5 m-0 text-right">
                <fa-icon [icon]="faFacebook" (click)="redirectToFacebook()" class="mr-2"></fa-icon>
                <fa-icon [icon]="faInstagram" (click)="redirectToInstagram()" class="mr-2"></fa-icon>
                <fa-icon [icon]="faVimeo" (click)="redirectToVimeo()"></fa-icon>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="openedModalServices2" [ngClass]="openedModalServices2 ? 'modalServices2 modal opened' : 'modalServices2 modal'">
        <div class="container-fluid">
          <div class="row">
            <div class="col-11 text-right">
              <h1 class="pt-5 szolgaltatasok-title">Szolgáltatások</h1>
            </div>
            <div class="col-1">
              <button class="navbar" (click)="toggleNavbar()">
                <div [ngClass]="modalNavbar ? 'toggle-opened' : 'toggle'">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="services-scrollable">
          <div class="container-fluid services-modal-blue-bg">
            <div class="row mt-4">
              <div class="col-10">
                <div class="title">Live stream/ broadcast</div>
                <div class="subtitle">Szolgáltatás</div>
              </div>
              <div class="col-2 text-right">
                <span class="close-btn" (click)="closeModalServices2()"><img src="assets/close-blue.png" width="45"/></span>
              </div>
            </div>
          </div>
          <div class="container-fluid services-modal-blue-bg">
            <div class="row">
              <div class="col-md-6">
                <div class="modal-col">
                  <p>Teljes körű streames szolgáltatás lebonyolítása</p>
                  <p>Élő műsorok, koncertek, talk show-k, konferenciák, oktató videók, online mettingek stb. online élő közvetítése</p>
                  <p>Profi csapatunk a tervezéstől a kivitelezésig segít mindenben</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="modal-col">
                  <p>Felvétel készítés, élő vágás, műsor rendezés, online közvetítés, grafikai arculat és animáció készítés, kreatív tervezés</p>
                  <p>Igény szerint a közvetített anyag rögzítése, archiválása, utómunkája</p>
                </div>
                <div class="video-container">
                  <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/427750124?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid service-modal-footer">
          <div class="row">
            <div class="col-9 p-2">
              <p class="p-2 ml-5 m-0 copyright"><strong>FLOW</strong>STREAMING &copy; 2020 All Rights Reserved</p>
            </div>
            <div class="col-3">
              <p class="social p-2 mr-5 m-0 text-right">
                <fa-icon [icon]="faFacebook" (click)="redirectToFacebook()" class="mr-2"></fa-icon>
                <fa-icon [icon]="faInstagram" (click)="redirectToInstagram()" class="mr-2"></fa-icon>
                <fa-icon [icon]="faVimeo" (click)="redirectToVimeo()"></fa-icon>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="openedModalServices3" [ngClass]="openedModalServices3 ? 'modalServices3 modal opened' : 'modalServices3 modal'">
        <div class="container-fluid">
          <div class="row">
            <div class="col-11 text-right">
              <h1 class="pt-5 szolgaltatasok-title">Szolgáltatások</h1>
            </div>
            <div class="col-1">
              <button class="navbar" (click)="toggleNavbar()">
                <div [ngClass]="modalNavbar ? 'toggle-opened' : 'toggle'">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="services-scrollable">
          <div class="container-fluid services-modal-orange-bg">
            <div class="row mt-4">
              <div class="col-10">
                <div class="subtitle"><strong>Eszköz</strong> bérbeadás</div>
              </div>
              <div class="col-2 text-right">
                <span class="close-btn" (click)="closeModalServices3()"><img src="assets/close-orange.png" width="45"/></span>
              </div>
            </div>
          </div>
          <div class="container-fluid services-modal-orange-bg">
            <div class="row">
              <div class="col-md-6">
                <div class="modal-col">
                  <p>Válogass a listánkról, találd meg a megfelelő eszközöket</p>
                  <p>Streaming eszközök, kamerák, kameramozgató eszközök, objektívek, világítás, hangtechnika, kiegészítők bérlése</p>
                  <p>technikai segítségnyújtás</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="modal-col">
                  <p>Az eszközlistáért kattints ide:</p>
                  <p class="pl-4 eszkozlista" (click)="redirectToKameraberles()"><img src="assets/eszkozlista.png"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid service-modal-footer">
          <div class="row">
            <div class="col-9 p-2">
              <p class="p-2 ml-5 m-0 copyright"><strong>FLOW</strong>STREAMING &copy; 2020 All Rights Reserved</p>
            </div>
            <div class="col-3">
              <p class="social p-2 mr-5 m-0 text-right">
                <fa-icon [icon]="faFacebook" (click)="redirectToFacebook()" class="mr-2"></fa-icon>
                <fa-icon [icon]="faInstagram" (click)="redirectToInstagram()" class="mr-2"></fa-icon>
                <fa-icon [icon]="faVimeo" (click)="redirectToVimeo()"></fa-icon>
              </p>
            </div>
          </div>
        </div>
      </div>


      <div [ngClass]="modalNavbar ? 'modalNavbar modal opened' : 'modalNavbar modal'">
        <div class="container-fluid">
          <div class="fs-navigation row">
            <div class="col-6">
              <img class="fs-logo mt-1" src="../../assets/flow_streaming.svg"/>
            </div>
            <div class="col-6">
              <button class="navbar" (click)="toggleNavbar()">
                <div [ngClass]="modalNavbar ? 'toggle-opened' : 'toggle'">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </div>
    <!--    <div class="container-fluid">-->
    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
              <div id="fs-navigation">
                <nav class="nav flex-column text-right mr-3">
                  <a class="nav-link" (click)="scrollToElement('top')">Főoldal</a>
                  <a class="nav-link" (click)="scrollToElement('services')">Szolgáltatások</a>
                  <a class="nav-link" (click)="scrollToElement('contacts')">Kapcsolat</a>
                </nav>
              </div>
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
      </div>
    </div>
  </div>

</div>


