<div id="error-wrapper" class="w-100">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center error-box">
        <p class="error-code">404</p>
        <p class="error-msg">A keresett oldal nem található!</p>
      </div>
    </div>
  </div>
</div>
