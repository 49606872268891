import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {faFacebookF, faInstagramSquare, faVimeoV} from '@fortawesome/free-brands-svg-icons';
import {Megrendeles} from '../model/megrendeles';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  faFacebook = faFacebookF;
  faInstagram = faInstagramSquare;
  faVimeo = faVimeoV;

  openedModalServices1 = false;
  openedModalServices2 = false;
  openedModalServices3 = false;

  modalNavbar = false;

  showContent = false;

  showSentEmail = false;
  showEmailError = false;

  model = new Megrendeles('', '', '', '');

  constructor(private http: HttpClient) { }

  onSubmit() {
    this.showSentEmail = false;
    this.showEmailError = false;
    this.http.post('/api/megrendeles', this.model).subscribe(
      (response) => {
        this.showSentEmail = true;
      },
      (error) => {
        this.showEmailError = true;
      }
    );
    this.model = new Megrendeles('', '', '', '');
  }

  ngOnInit(): void {
    const elements = document.getElementsByClassName('fs-content');

    // setTimeout(() => {
    //   this.showContent = true;
    //   elements[0].classList.remove('fs-absolute');
    //   elements[0].classList.remove('fs-hidden');
    // }, 6500);

    setTimeout(() => {
      elements[0].classList.remove('fs-absolute');
      setTimeout(() => {
        this.showContent = true;
        elements[0].classList.remove('fs-hidden');
      }, 500);
    }, 5000);

  }

  openModalServices1() {
    this.openedModalServices1 = !this.openedModalServices1;
  }

  closeModalServices1() {
    const elements = document.getElementsByClassName('modalServices1');
    elements[0].classList.add('fadeout');
    setTimeout(() => {
      elements[0].classList.remove('fadeout');
      this.openedModalServices1 = !this.openedModalServices1;
    }, 450);
  }

  openModalServices2() {
    this.openedModalServices2 = !this.openedModalServices2;
  }

  closeModalServices2() {
    const elements = document.getElementsByClassName('modalServices2');
    elements[0].classList.add('fadeout');
    setTimeout(() => {
      elements[0].classList.remove('fadeout');
      this.openedModalServices2 = !this.openedModalServices2;
    }, 450);
  }

  openModalServices3() {
    this.openedModalServices3 = !this.openedModalServices3;
  }

  closeModalServices3() {
    const elements = document.getElementsByClassName('modalServices3');
    elements[0].classList.add('fadeout');
    setTimeout(() => {
      elements[0].classList.remove('fadeout');
      this.openedModalServices3 = !this.openedModalServices3;
    }, 450);
  }

  redirectToKameraberles() {
    document.location.href = 'http://kameraberles.com/berelheto-eszkozok';
  }

  redirectToFacebook() {
    document.location.href = 'https://facebook.com';
  }

  redirectToVimeo() {
    document.location.href = 'https://vimeo.com/flowproduction';
  }

  redirectToInstagram() {
    document.location.href = 'https://www.instagram.com/flowproduction_official/';
  }

  toggleNavbar() {
    if (this.modalNavbar) {
      const elements = document.getElementsByClassName('modalNavbar');
      const button = document.getElementsByClassName('toggle-opened');
      elements[0].classList.add('fadeout');
      button[0].classList.remove('toggle-opened');
      button[0].classList.add('toggle');
      setTimeout(() => {
        elements[0].classList.remove('fadeout');
        this.modalNavbar = !this.modalNavbar;
      }, 400);
    } else {
      this.modalNavbar = !this.modalNavbar;
    }
  }

  scrollToElement(className: string): void {
    this.toggleNavbar();
    const element = document.getElementById(className);
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll($event) {
    // const yelements = document.getElementsByClassName('moving-object');
    // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < yelements.length; i++) {
    //   const element = yelements[i];
    //   if (!element.classList.contains('mv-shake')) {
    //     element.classList.add('mv-shake');
    //     setTimeout(() => {
    //       element.classList.remove('mv-shake');
    //     }, 2000);
    //   }
    // }
  }
}
